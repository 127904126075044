<template>
    <c-panel>
        <div class="mt-5 text-center">
            <h1 style="font-size: 3.5rem">Page Not Found</h1>
            <p>The page you're looking for doesn't seem to exist.</p>
            <router-link to="/" tag="button" class="btn btn-primary">Go to Home</router-link>
            <!-- TODO: Provide Search or Related Articles -->
        </div>
    </c-panel>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class PageNotFound extends Vue {}
</script>
